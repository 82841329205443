<template>
  <div style="height: 100%">
    <!-- 中心部分 -->
    <div class="bodey">
      <!-- 单选行左侧部分 -->
      <div class="bodyrodoi">
        <div class="dialysisType flex-between">
          <div class="rodoi-left" :class="dialysisTypeLeft == 0 ? 'default' : ''" v-if="isdialysisItem"
            @click="dialysisTypeBut('left')">
            <i class="fa fa-angle-left"></i>
          </div>
          <div class="bodyrodoiBox" ref="dialysisTypeRef">
            <div ref="dialysisItemRef" class="flex-start bodyrodoiBoxItem" :style="{
                left: dialysisTypeLeft + 'px',
              }">
              <div v-for="item in dictList[100000000]" :key="item.code">
                <div class="radios" :class="[actDialyse.code === item.code ? 'active' : '']" @click="rodeiclis(item)">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="rodoi-right" :class="dialysisTypeLeft == -dialysisTypeWin ? 'default' : ''" v-if="isdialysisItem"
            @click="dialysisTypeBut('right')">
            <i class="fa fa-angle-right"></i>
          </div>
        </div>

        <!--  右侧部分 -->
        <!-- <div class="ruboy">
          <div style="width: 40%">您已选择:</div>
          <div style="width: 60%">{{ actDialyse.name }}</div>
        </div> -->
        <div class="btnr">
          <el-dropdown @command="modTemp">
            <el-button class="color-blue">
              <i class="fa fa-file-text-o"></i>
              模板
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <!-- <el-dropdown-item command="1">编辑模板</el-dropdown-item> -->
                <el-dropdown-item command="2">使用模板</el-dropdown-item>
                <el-dropdown-item command="3">存为模板</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-dropdown @command="copyAppointment">
            <el-button class="color-green">
              <i class="fa fa-file-text-o"></i>
              复制
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="this_week">
                  将本周
                  <span style="font-weight: bold">
                    （{{ searchData.week }}周）
                  </span>
                  排床复制到下周
                  <span style="font-weight: bold">
                    （{{ searchData.week + 1 }}周）
                  </span>
                </el-dropdown-item>
                <el-dropdown-item command="last_week">
                  将上周
                  <span style="font-weight: bold">
                    （{{ searchData.week - 1 }}周）
                  </span>
                  排床复制到下周
                  <span style="font-weight: bold">
                    （{{ searchData.week + 1 }}周）
                  </span>
                </el-dropdown-item>
                <el-dropdown-item command="before_last">
                  将上上周
                  <span style="font-weight: bold">
                    （{{ searchData.week - 2 }}周）
                  </span>
                  排床复制到下周
                  <span style="font-weight: bold">
                    （{{ searchData.week + 1 }}周）
                  </span>
                </el-dropdown-item>
                <el-dropdown-item command="two_week">
                  将前两周(包含本周;
                  <span style="font-weight: bold">
                    {{ searchData.week - 1 }},{{ searchData.week }}周
                  </span>
                  ）排床复制到下两周
                  <span style="font-weight: bold">
                    （{{ searchData.week + 1 }},{{ searchData.week + 2 }}周）
                  </span>
                </el-dropdown-item>
                <el-dropdown-item command="three_week">
                  将前三周(包含本周;
                  <span style="font-weight: bold">
                    {{ searchData.week - 2 }},{{ searchData.week - 1 }},{{
                    searchData.week
                    }}周
                  </span>
                  ）排床复制到下三周
                  <span style="font-weight: bold">
                    （{{ searchData.week + 1 }},{{ searchData.week + 2 }},{{
                    searchData.week + 3
                    }}周）
                  </span>
                </el-dropdown-item>
                <el-dropdown-item command="four_week">
                  将前四周(包含本周;
                  <span style="font-weight: bold">
                    {{ searchData.week - 3 }},{{ searchData.week - 2 }},{{
                    searchData.week - 1
                    }},{{ searchData.week }}周
                  </span>
                  ）排床复制到下四周
                  <span style="font-weight: bold">
                    （{{ searchData.week + 1 }},{{ searchData.week + 2 }},{{
                    searchData.week + 3
                    }},{{ searchData.week + 4 }}周）
                  </span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-button v-print="print" class="color-grey">
            <i class="fa fa-print"></i>
            打印
          </el-button>
          <!-- <el-button @click="printData" class="color-grey">
            <i class="fa fa-print"></i>
            打印
          </el-button>
          <el-button @click="printData1" class="color-grey">
            <i class="fa fa-print"></i>
            打印1
          </el-button> -->
          <el-dropdown @command="exportMent">
            <el-button class="color-vice">
              <i class="fa fa-file-text-o"></i>
              导出
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="1">按界面导出</el-dropdown-item>
                <el-dropdown-item command="2">按患者导出</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>

      <div class="appointmentCont flex-start">
        <div class="patientList">
          <el-input v-model="searchData.search_code" class="w-50 m-2" placeholder="搜索" @keydown.enter="getPatientList">
            <template #suffix>
              <el-icon class="el-input__icon" @click="getPatientList">
                <search />
              </el-icon>
            </template>
          </el-input>
          <el-checkbox class="my-patient-check" v-model="searchData.my" @change="getPatientList">
            我的患者
          </el-checkbox>
          <div class="lifclisBox">
            <el-scrollbar>
              <div class="lifclis" :class="[actPatient.id === items.id ? 'active' : '']" v-for="items in Nlist"
                :key="items.id" @click="Bhzclic(items)">
                <template v-if="items.name">
                  <div style="display: flex; align-items: center">
                    <!-- 鼠标移入显示病患信息 -->
                    <div>
                      <el-popover placement="right" :width="220" trigger="hover">
                        <template #reference>
                          <div v-if="items.infect.length > 0" class="Fclis">
                            {{ items.infect[0].dict_disease_name }}
                          </div>
                        </template>
                        <div style="display: flex">
                          <div>姓名：</div>
                          <div>{{ items.name }}</div>
                        </div>
                        <div style="display: flex; margin: 2px 0">
                          <div>性别：</div>
                          <div>{{ items.dict_sex_name }}</div>
                        </div>
                        <div style="display: flex; margin: 2px 0">
                          <div>病历号：</div>
                          <div v-if="items.his">{{ items.his.case_no }}</div>
                        </div>
                        <div style="display: flex">
                          <div>感染类型：</div>
                          <div v-for="(rows, indexs) in items.infect" :key="indexs" style="margin: 0 2px">
                            {{ rows.dict_disease_name }}
                          </div>
                        </div>
                      </el-popover>
                    </div>
                    <div class="nacliss">
                      {{ items.name }}
                      <span style="font-weight: 400; color: #70d240">
                        ({{ items.age }})</span>
                    </div>
                  </div>
                  <div>
                    <div :class="[items.bed.length > 0 ? 'numbcls' : 'numbclss']">
                      {{ items.bed.length }}
                    </div>
                  </div>
                </template>
              </div>
            </el-scrollbar>
          </div>
        </div>
        <div class="cont-table">
          <el-form :inline="true" :model="tableSearch" ref="searchForm" class="demo-form-inline w-144">
            <el-select v-model="tableSearch.hospital_area_id" @change="hospitalChe" placeholder="请选择">
              <el-option v-for="item in hospitalList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form>
          <div class="operation flex-between">
            <div class="checkbox">
              <el-checkbox v-model="isDrag" label="拖动换床"></el-checkbox>
              <el-checkbox v-model="isRowbed" label="直接排床"></el-checkbox>
            </div>
            <div style="
                display: flex;
                justify-content: space-between;
                width: 250px;
              ">
              <div class="iconcliss" @click="leftBeds('down')">
                <el-icon>
                  <arrow-left-bold />
                </el-icon>
              </div>
              <div class="textclss">第</div>
              <div style="
                  width: 80px;
                  background: rgba(49, 102, 174, 1);
                  height: 32px;
                  line-height: 32px;
                  text-align: center;
                  color: #fff;
                  border-radius: 4px;
                ">
                {{ searchData.week }}
              </div>
              <div class="textclss">周</div>
              <div class="iconcliss" @click="leftBeds('add')">
                <el-icon>
                  <arrow-right-bold />
                </el-icon>
              </div>
            </div>
          </div>
          <div class="table" ref="tableRef">
            <el-table class="sss1" :data="tableData" v-loading="dataTableLoading" :span-method="arraySpanMethod"
              row-key="id" size="small" :header-cell-style="{ textAlign: 'center', borderColor: '#fff' }"
              :cell-style="{ textAlign: 'center' }" v-if="istable" :max-height="tableHei" border>
              <el-table-column fixed prop="name" label="组号" width="80" />
              <el-table-column fixed label="床号" width="80">
                <template #default="scope">
                  <el-tooltip placement="right" effect="light">
                    <template #content>
                      <div class="tooltipCont">
                        <div>床位号：{{ scope.row.bed_no }}</div>
                        <template v-if="scope.row.machine">
                          <div>
                            感染类型：{{
                            scope.row.machine.infect_flag_name.join(' ')
                            }}
                          </div>
                          <div>
                            治疗模式：{{
                            scope.row.machine.dict_treat_model_name.join(' ')
                            }}
                          </div>
                        </template>
                        <template v-else>
                          <div>感染类型：</div>
                          <div>治疗模式：</div>
                        </template>
                      </div>
                    </template>
                    <div>
                      <div class="bedName bedcul">{{ scope.row.bed_no }}</div>
                      <!-- <div class="flex-between plain">
                        <el-tag
                          class="mx-1"
                          v-if="scope.row.dict_infect_flag_name"
                          type="danger"
                          effect="dark"
                        >
                          {{ scope.row.dict_infect_flag_name }}
                        </el-tag>
                        <el-tag class="mx-1" effect="plain">
                          {{ scope.row.machine.dict_treat_model_name[0] }}
                        </el-tag>
                      </div> -->
                    </div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column v-for="(item, index) in weekList" :key="index">
                <template #header> {{ item.week }} {{ item.date }} </template>
                <el-table-column v-for="item2 in item.sun" :key="item2.id" :label="item2.name" width="80">
                  <el-table-column width="80" :column-key="item2.id">
                    <template #header>
                      <div class="bedSubtotal" v-if="bedSubtotal[item2.id]">
                        <el-tooltip placement="top" effect="light">
                          <template #content>
                            <div class="tooltipCont">
                              <div>
                                排床小计 - {{ item2.week }}{{ item2.name }}
                              </div>
                              <div>
                                总床位数： {{ bedSubtotal[item2.id].total }} /
                                {{ tableData.length }}
                              </div>
                              <div class="item" v-for="(item3, index3) in bedSubtotal[item2.id]
                                  .sun" :key="index3">
                                {{ index3 }}:{{ item3 }}
                              </div>
                            </div>
                          </template>
                          <div class="num">
                            {{ bedSubtotal[item2.id].total }}/{{
                            tableData.length
                            }}
                          </div>
                        </el-tooltip>
                      </div>
                      <div class="bedSubtotal" v-else>
                        0/{{ tableData.length }}
                      </div>
                    </template>
                    <template #default="scope">
                      <div :draggable="isDrag" class="thead-cell" :class="
                          scope.row[item2.id] &&
                          scope.row[item2.id].patient_id == actPatient.id
                            ? 'active'
                            : ''
                        " v-if="scope.row[item2.id]" @dragstart="(el) => handleDragstart(el, scope)"
                        @dragend="(el) => handleDragend(el, scope)" @drop="(el) => handleDrop(el, scope)"
                        @dragenter="(el) => handleDragenter(el, scope)" @dragover="(el) => handleDragenter(el, scope)"
                        @click="handleClick(scope.row, item2)" @contextmenu.prevent="
                          openMenu(scope.row[item2.id], $event, scope.row)
                        ">
                        <i class="fa fa-times-circle" @click.prevent.stop="
                            delBedItem(scope.row[item2.id].id)
                          "></i>
                        <div class="patientName">
                          {{ scope.row[item2.id].patient_name }}
                        </div>
                        {{ scope.row[item2.id].nm_dialyse_way }}
                      </div>
                      <div class="thead-cell" v-else @drop="(el) => handleDrop(el, scope)"
                        @dragenter="(el) => handleDragenter(el, scope)" @dragover="(el) => handleDragenter(el, scope)"
                        @click="handleClick(scope.row, item2)"></div>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
            </el-table>
            <ul v-show="visible" :style="{
                left: contextmenuData.left + 'px',
                top: contextmenuData.top + 'px',
              }" class="contextmenu">
              <li>
                透析方式
                <ul class="contextmenuItem">
                  <li @click="dialysistype(item)" v-for="item in dictList[100000000]" :key="item.code">
                    {{ item.name }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="printArea" id="printArea">
            <el-table :data="tableData" class="printTab" v-loading="dataTableLoading" :span-method="arraySpanMethod"
              row-key="id" size="small"  border
              >
              <el-table-column prop="name" label="组号" width="50" />
              <el-table-column label="床号" width="50" prop="bed_no">
              </el-table-column>
              <el-table-column v-for="(item, index) in weekList" :key="index">
                <template #header> {{ item.week }} {{ item.date }} </template>
                <el-table-column v-for="item2 in item.sun" :key="item2.id" :label="item2.name" width="60">
                  <el-table-column width="60" :column-key="item2.id">
                    <template #header>
                      <div class="bedSubtotal" v-if="bedSubtotal[item2.id]">
                        <div class="num">
                          {{ bedSubtotal[item2.id].total }}/{{
                          tableData.length
                          }}
                        </div>
                      </div>
                      <div class="bedSubtotal" v-else>
                        0/{{ tableData.length }}
                      </div>
                    </template>
                    <template #default="scope">
                      <div :draggable="isDrag" class="thead-cell" v-if="scope.row[item2.id]">
                        <div class="patientName">
                          {{ scope.row[item2.id].patient_name }}
                        </div>
                        {{ scope.row[item2.id].nm_dialyse_way }}
                      </div>
                      <div class="thead-cell" v-else></div>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <BaseDialog title="排床提醒" :isshow="visibleRepeat" @handleShow="
        (val) => {
          visibleRepeat = val
        }
      " width="650px" top="20%">
      <div class="tongluBox">
        {{ title }}
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button class="color-cancel" type="primary" @click="visibleRepeat = false">
            取 消
          </el-button>
          <el-button @click="accessItem('only')" class="color-cancel" type="primary">
            添加且删除当天其他班次
          </el-button>
          <el-button @click="accessItem('both')" class="color-determine" type="primary">
            添加且不删除当天其他班次
          </el-button>
        </div>
      </template>
    </BaseDialog>

    <useTemplateList ref="useTemplateListRef" @refreshDataList="useTempUp"></useTemplateList>
    <addTemplateList ref="addTemplateListRef"></addTemplateList>
    <div id="printArea1" style="display: none;"><printDemo ref="printDemoRef" ></printDemo></div>
    <!-- <demo :excel="excel" id="printArea1" ></demo> -->
  </div>
</template>

<script>
  import printDemo from './printDemo'
  import demo from './demo'
  import {
    reactive,
    toRefs,
    onMounted,
    watch,
    onBeforeUnmount,
    computed,
    nextTick,
    getCurrentInstance,
    
  } from 'vue'
  import axios from 'axios'
  import comm from '@/utils/comm'
  import { Search, ArrowRightBold, ArrowLeftBold } from '@element-plus/icons-vue'
  import { useStore } from 'vuex'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { gitDictList, downLoadXlsx } from '@/utils/tool'
  import service from '@/utils/request'
  import BaseDialog from '@/components/Dialog/index.vue'
  import { useRouter } from 'vue-router'
  import useTemplateList from './useTemplateList.vue'
  import addTemplateList from './addTemplateList.vue'
  export default {
    components: {
      Search,
      ArrowRightBold,
      ArrowLeftBold,
      BaseDialog,
      useTemplateList,
      addTemplateList,
      printDemo,
      demo
    },
    setup() {
      let { proxy } = getCurrentInstance()
      const userInfo = localStorage.getItem('ms_userData')
      const state = reactive({
        searchData: {
          week: '',
        },
        print: {
          id: 'printArea',
          popTitle: '打印小条',
        },
        tableSearch: {
          week: '',
          hospital_area_id: userInfo.hospital,
        },
        pageData: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        dataListLoading: false,
        visible: false,
        tableRef: null,
        istable: true,
        tableHei: 0,
        contextmenuData: {
          left: 0,
          top: 0,
          selectedTag: {},
        },
        bleData: [],
        tableData: [],
        dictList: {},
        Nlist: [],
        hospitalList: [],
        weekList: [],
        bedList: [],
        actDialyse: {},
        actPatient: {},
        dragState: {
          startIndex: -1, // 拖动起始元素的index
          endIndex: -1, // 拖动结束元素的index
          startRow: {}, // 拖动起始元素的行数据
          endRow: {}, // 拖动结束元素的行数据
          dragging: false, // 是否正在拖动
        },
        excel:'',
        isDrag: true,
        isRowbed: true,
        dataTableLoading: false,
        visibleRepeat: false,
        repeatMsg: '',
        title: '',
        addBedParam: {},
        bedSubtotal: {},
        dialysisTypeRef: null,
        dialysisItemRef: null,
        isdialysisItem: true,
        dialysisTypeWin: 0,
        dialysisTypeLeft: 0,
        useTemplateListRef: null,
        addTemplateListRef: null,
      })

      const store = useStore()
      const router = useRouter()
      const collapse = computed(() => store.state.collapse)
      watch(collapse, () => {
        setTimeout(() => {
          dialysisTypeInit()
        }, 300)
      })
      const dialysisTypeInit = () => {
        let typeWin = state.dialysisTypeRef.clientWidth
        let itemWin = state.dialysisItemRef.clientWidth
        if (itemWin > typeWin) {
          state.isdialysisItem = true
          state.dialysisTypeWin = itemWin - typeWin
        } else {
          state.isdialysisItem = false
          state.dialysisTypeWin = 0
        }
        // console.log(state.dialysisTypeWin)
      }
      const dialysisTypeBut = (type) => {
        if (!state.dialysisTypeWin) return
        if (type === 'left') {
          if (state.dialysisTypeLeft === 0) return
          if (state.dialysisTypeLeft < -200) {
            state.dialysisTypeLeft += 200
          } else {
            state.dialysisTypeLeft = 0
          }
        } else {
          const par = state.dialysisTypeLeft * -1

          if (par === state.dialysisTypeWin) return
          if (par + 200 < state.dialysisTypeWin) {
            state.dialysisTypeLeft += -200
          } else {
            state.dialysisTypeLeft = state.dialysisTypeWin * -1
          }
          // console.log(state.dialysisTypeLeft)
        }
      }
      const modTemp = (val) => {
        if (val == 1) {
          router.push('/appointment/templateList')
        } else if (val == 2) {
          state.useTemplateListRef.initData(state.searchData.week)
        } else if (val == 3) {
          state.addTemplateListRef.initData(state.searchData.week)
        }
      }
      const exportMent = (val) => {
        if (val == 1) {
          downLoadXlsx(
            '/api/schedule_beds/export_page',
            { week: state.searchData.week },
            '患者排床按界面导出.xlsx'
          )
        } else if (val == 2) {
          downLoadXlsx(
            '/api/schedule_beds/export_patient',
            { week: state.searchData.week },
            '患者排床按患者导出.xlsx'
          )
        }
      }
      const copyAppointment = (val) => {
        ElMessageBox.confirm('复制的数据将会覆盖目的周的数据', '操作提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let res = await service.post('/api/schedule_beds/copy', {
              week: state.tableSearch.week,
              type: val,
            })
            if (res.code === 0) {
              ElMessage.success(res.msg)
            }
          })
          .catch(() => { })
      }
      const useTempUp = () => {
        getMOnTOSun()
        getDataList()
      }
      onMounted(() => {
        state.istable = false
        nextTick(() => {
          state.tableHei = state.tableRef.clientHeight
          state.istable = true
        })
        initData()
       
        window.addEventListener('resize', dialysisTypeInit)
        window.onresize = () => {
          console.log(state.tableRef.clientHeight)
          state.tableHei = state.tableRef.clientHeight
        }
      })
      onBeforeUnmount(() => {
        window.removeEventListener('resize', dialysisTypeInit)
      })

      const initData = async () => {
        gitDict() // 字典数据
        getHospitalList()
        await getWeek()
        await getPatientList()
        getMOnTOSun()
        await getBedList()
        getDataList()
        // printData()
      }
      const leftBeds = (type) => {
        if (type === 'add') {
          state.searchData.week++
          state.tableSearch.week++
        } else {
          state.searchData.week--
          state.tableSearch.week--
        }
        getPatientList()
        getMOnTOSun()
        getDataList()
      }
      const gitDict = async () => {
        state.dictList = await gitDictList(['100000000'])
        nextTick(() => {
          dialysisTypeInit()
        })
        if (state.dictList[100000000].length > 0) {
          state.actDialyse = state.dictList[100000000][0]
        }
      }
      const getHospitalList = async () => {
        let res = await service.post('/api/hospital/list', {
          parent_id: 0,
        })
        if (res.code === 0) {
          state.hospitalList = res.data
          if (!state.tableSearch.hospital_area_id) {
            state.tableSearch.hospital_area_id = res.data[0].id
            state.tableSearch.hospital_area_name = res.data[0].name
          }
        }
      }
      const getWeek = async () => {
        let res = await service.post('/api/week/get_week')
        if (res.code === 0) {
          state.searchData.week = res.data.week
          state.tableSearch.week = res.data.week
        }
      }
      const getMOnTOSun = async () => {
        let res = await service.post('/api/week/mon_to_sun', {
          week: state.searchData.week,
        })
        if (res.code === 0) {
          res.data.map((item, index) => {
            let par = [
              {
                name: '上午',
                id: index + ',1',
                week: item.week,
                date: item.date,
              },
              {
                name: '下午',
                id: index + ',2',
                week: item.week,
                date: item.date,
              },
              {
                name: '晚上',
                id: index + ',3',
                week: item.week,
                date: item.date,
              },
            ]
            item.sun = par
          })
          state.weekList = res.data
        }
      }
      const getBedList = async () => {
        let res = await service.post('/api/schedule_beds/bed', {
          hospital_area_id: state.tableSearch.hospital_area_id,
        })
        if (res.code === 0) {
          state.bedList = res.data
          getBedData()
        }
      }
      const getBedData = () => {
        let row = []
        state.bedList.map((item) => {
          item.uID = item.id
          item.bed.map((item2, index2) => {
            let param = {
              ...item,
              ...item2,
            }
            if (index2 === 0) {
              param.column = item.bed.length
            }
            row.push(param)
          })
        })
        state.bleData = row
      }
      const getPatientList = async () => {
        state.dataListLoading = true
        let res = await service.post(
          '/api/schedule_beds/patient_list',
          state.searchData
        )
        state.dataListLoading = false
        if (res.code === 0) {
          state.Nlist = res.data
          if (res.data.length > 0 && !state.actPatient.id) {
            state.actPatient = res.data[0]
          }
        }
      }
      const getDataList = async () => {
        state.dataTableLoading = true
        let res = await service.post('/api/schedule_beds/list', state.tableSearch)
        state.dataTableLoading = false
        if (res.code === 0) {
          getTableList(res.data)
        }
      }
      const arraySpanMethod = ({ row, columnIndex }) => {
        if (columnIndex === 0) {
          if (row.column) {
            return {
              rowspan: row.column,
              colspan: 1,
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            }
          }
        }
      }

      const hospitalChe = async (val) => {
        state.hospitalList.map((item) => {
          if (item.id === val) {
            state.tableSearch.hospital_area_name = item.name
          }
        })
        await getPatientList()
        await getBedList()
        getDataList()
      }

      const getTableList = (row) => {
        state.bedSubtotal = {}
        getBedData()
        row.map((item) => {
          let index = getColumKey(item)
          state.bleData.map((item2) => {
            if (item.bed_num === item2.bed_no) {
              item2[index] = item
              if (
                !Object.prototype.hasOwnProperty.call(state.bedSubtotal, index)
              ) {
                state.bedSubtotal[index] = {
                  total: 0,
                  sun: {},
                }
              }
              state.bedSubtotal[index].total++
              getDialyseNum(state.bedSubtotal[index].sun, item.nm_dialyse_way)
            }
          })
        })
        // console.log(state.bleData)
        state.tableData = state.bleData
      }
      const getDialyseNum = (row, val) => {
        if (Object.prototype.hasOwnProperty.call(row, val)) {
          row[val]++
        } else {
          row[val] = 1
        }
      }
      // 获取列key
      const getColumKey = (item) => {
        let par = []
        switch (item.weekday) {
          case '周一':
            par[0] = 0
            break
          case '周二':
            par[0] = 1
            break
          case '周三':
            par[0] = 2
            break
          case '周四':
            par[0] = 3
            break
          case '周五':
            par[0] = 4
            break
          case '周六':
            par[0] = 5
            break
          case '周日':
            par[0] = 6
            break
        }
        switch (item.nm_name) {
          case '上午':
            par[1] = 1
            break
          case '下午':
            par[1] = 2
            break
          case '晚上':
            par[1] = 3
            break
        }
        return par.join(',')
      }

      //开始移动
      const handleDragstart = (el, item) => {
        state.dragState.dragging = true
        state.dragState.startIndex = item.column.columnKey
        state.dragState.startRow = item.row
        // el.target.style.backgroundColor = 'rgba(49, 102, 174, 0.3)'
      }
      const handleDragend = () => {
        // el.target.style.backgroundColor = '#fff'
      }
      // 结束移动
      const handleDrop = (el, item) => {
        state.dragState.endIndex = item.column.columnKey // 记录结束列index
        state.dragState.endRow = item.row
        if (
          state.dragState.startIndex !== state.dragState.endIndex ||
          state.dragState.startRow.bed_no !== state.dragState.endRow.bed_no
        ) {
          dragSwap(state.dragState)
        }
        finishDragInit()
      }
      //移动中
      const handleDragenter = (el) => {
        el = el || event
        if (el.preventDefault) {
          el.preventDefault()
        } else {
          el.returnValue = false
        }
      }
      const finishDragInit = () => {
        state.dragState = {
          startIndex: -1, // 拖动起始元素的index
          endIndex: -1, // 拖动结束元素的index
          startRow: {}, // 拖动起始元素的行数据
          endRow: {}, // 拖动结束元素的行数据
          dragging: false, // 是否正在拖动
        }
      }
      // 交换判断
      const dragSwap = async (item) => {
        // 移动后有床位,直接交换
        if (item.endRow[item.endIndex]) {
          let cont = `确认把（${item.startRow[item.startIndex].patient.name} - ${item.startRow[item.startIndex].schedule_date
            } - ${item.startRow[item.startIndex].nm_name} - ${item.startRow[item.startIndex].bed_num
            }）和（${item.endRow[item.endIndex].patient.name} - ${item.endRow[item.endIndex].schedule_date
            } - ${item.endRow[item.endIndex].nm_name} - ${item.endRow[item.endIndex].bed_num
            }）交换床位？`
          ElMessageBox.confirm(cont, '操作提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(async () => {
              let res = await service.post('/api/schedule_beds/swap', {
                from_id: item.startRow[item.startIndex].id,
                to_id: item.endRow[item.endIndex].id,
              })
              if (res.code === 0) {
                ElMessage.success(res.msg)
                getDataList()
              }
            })
            .catch(() => { })
        } else {
          let indexArr = item.endIndex.split(',')
          let columnData = state.weekList[indexArr[0]].sun[indexArr[1] - 1]
          let param = {
            from_id: item.startRow[item.startIndex].id,
            to_id: 0,
            bed_num: item.endRow.bed_no,
            dict_group_name: item.endRow.uID,
            nm_name: columnData.name,
            schedule_date: columnData.date,
            week: columnData.week,
          }
          let res = await service.post('/api/schedule_beds/swap', param)
          if (res.code === 0) {
            ElMessage.success(res.msg)
            getDataList()
          }
        }
      }
      // 表格点击
      const handleClick = (row, item) => {
        if (row[item.id] || state.dragState.dragging) {
          return
        }
        if (!state.actDialyse.code) {
          ElMessage({
            message: '请先选择透析方式',
            type: 'warning',
          })
          return
        }
        if (!state.actPatient.id) {
          ElMessage({
            message: '请先选择患者',
            type: 'warning',
          })
          return
        }
        if (state.isRowbed) {
          addBeds(row, item)
        } else {
          ElMessage({
            message: '请勾选"点击直接排床"后再进行排床操作',
            type: 'warning',
          })
        }
      }

      // 添加排床
      const addBeds = async (row, item, type) => {
        let param = {}
        // type 当天是否有排床 true 有
        if (!type) {
          param = {
            bed_num: row.bed_no,
            dict_dialyse_way: state.actDialyse.code,
            nm_dialyse_way: state.actDialyse.name,
            dict_group_name: row.uID,
            nm_group_name: row.name,
            hospital_area_id: state.tableSearch.hospital_area_id,
            hospital_area_name: state.tableSearch.hospital_area_name,
            nm_name: item.name,
            patient_id: state.actPatient.id,
            schedule_date: item.date,
            week: item.week,
          }
          state.addBedParam = param
        } else {
          param = row
          param.other = type
        }

        let res = await service.post('/api/schedule_beds/create', param)
        if (res.code === 0) {
          ElMessage.success(res.msg)
          getDataList()
          getPatientList()
        } else if (res.code === -66) {
          state.title = res.msg
          state.visibleRepeat = true
        }
      }

      watch(
        () => state.visible,
        (value) => {
          if (value) {
            document.body.addEventListener('click', closeMenu)
          } else {
            document.body.removeEventListener('click', closeMenu)
          }
        }
      )
      const openMenu = (tag, e) => {
        const menuMinWidth = 90
        const offsetLeft = state.tableRef.getBoundingClientRect().left
        const offsetTop = state.tableRef.getBoundingClientRect().top
        const offsetWidth = state.tableRef.offsetWidth
        const maxLeft = offsetWidth + menuMinWidth
        const left = e.clientX - offsetLeft + 15

        if (left > maxLeft) {
          state.contextmenuData.left = maxLeft
        } else {
          state.contextmenuData.left = left
        }

        state.contextmenuData.top = e.clientY - offsetTop
        state.visible = true
        state.contextmenuData.selectedTag = tag
      }
      const dialysistype = async (item) => {
        let res = await service.post('/api/schedule_beds/change_touxi', {
          dict_dialyse_way: item.code,
          nm_dialyse_way: item.name,
          id: state.contextmenuData.selectedTag.id,
        })
        if (res.code === 0) {
          ElMessage.success(res.msg)
          getDataList()
          getPatientList()
        }
      }
      const closeMenu = () => {
        state.visible = false
      }

      const delBedItem = (id) => {
        ElMessageBox.confirm('是否确定删除该数据?', '操作提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            delBeds(id)
          })
          .catch(() => { })
      }
      const delBeds = async (id) => {
        let res = await service.post('/api/schedule_beds/delete', {
          id: id,
        })
        if (res.code === 0) {
          ElMessage.success(res.msg)
          getDataList()
          getPatientList()
        }
      }
      const accessItem = (type) => {
        addBeds(state.addBedParam, '', type)
        state.visibleRepeat = false
      }

      const rodeiclis = (item) => {
        state.actDialyse = item
      }
      const Bhzclic = (item) => {
        state.actPatient = item
      }
      const printData = () => {
        proxy.$refs.printDemoRef.initData(state.searchData.week)
        // let data = {
        //   week: state.searchData.week
        // }
        // let url = '/api/export/scheduleBed'
        // let port = location.protocol
        // let ip = comm.cesuUrl
        // let requestUrl = `${port}//${ip}${url}`
        // axios({
        //   headers: {
        //     token: localStorage.getItem('ms_token'),
        //   },
        //   method: 'post',
        //   responseType: 'blob',
        //   url: requestUrl,
        //   data: data,
        // })
        //   .then((res) => {
        //     const link = document.createElement('a')
        //     let blob = new Blob([res.data], {
        //       type: 'application/vnd.ms-excel',
        //     })
        //     state.excel = URL.createObjectURL(blob)
        //     // console.log(state.excel)
        //     // proxy.$refs.printDemoRef.initData(state.excel)
        //     // nextTick(() => {
        //     //   proxy.$refs.printDemoRef.print()
        //     // })
        //   })
        //   .catch((err) => {
        //     console.log(err)
        //   })
      }
      const printData1 = () => {
        // downLoadXlsx(
        //     '/api/export/scheduleBed',
        //     { week: state.searchData.week },
        //     '患者排床按患者导出.xlsx'
        //   )
        let data = {
          week: state.searchData.week
        }
        let url = '/api/export/scheduleBed'
        let port = location.protocol
        let ip = comm.cesuUrl
        let requestUrl = `${port}//${ip}${url}`
        axios({
          headers: {
            token: localStorage.getItem('ms_token'),
          },
          method: 'post',
          responseType: 'blob',
          url: requestUrl,
          data: data,
        })
          .then((res) => {
            const link = document.createElement('a')
            let blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel',
            })
            state.excel = URL.createObjectURL(blob)
            console.log(state.excel)
            // proxy.$refs.printDemoRef.initData(state.excel)
            // nextTick(() => {
            //   proxy.$refs.printDemoRef.print()
            // })
          })
          .catch((err) => {
            console.log(err)
          })
      }
      return {
        ...toRefs(state),
        rodeiclis,
        Bhzclic,
        arraySpanMethod,
        handleDragstart,
        handleDragend,
        handleDrop,
        handleDragenter,
        handleClick,
        delBedItem,
        leftBeds,
        hospitalChe,
        getPatientList,
        accessItem,
        openMenu,
        dialysisTypeInit,
        dialysisTypeBut,
        dialysistype,
        modTemp,
        exportMent,
        copyAppointment,
        useTempUp,
        printData,
        printData1
      }
    },
  }
</script>
<style media="print">
  @page {
    /* size: auto; */
    margin: 0;
    width: 209mm;
    height: 295mm;
  }
</style>
<style scoped lang="scss">
  /* #printArea1{
    width: 100%;
    height: 800px;
  } */
  .appointmentCont {
    height: calc(100% - 40px);
  }

  .el-button {
    margin-left: 8px;
  }

  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    width: 86px;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    border: 1px solid rgb(238, 238, 238);

    li {
      margin: 0;
      padding: 7px 16px;
      width: 90px;
      cursor: pointer;
      color: #666;

      &:hover {
        color: #3166ae;

        .contextmenuItem {
          display: block;
        }
      }

      .contextmenuItem {
        display: none;
        list-style-type: none;
        position: absolute;
        bottom: -2px;
        left: 90px;
        background: #fff;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
        border: 1px solid rgb(238, 238, 238);
        z-index: 3001;

        li {
          padding: 6px 16px;
          width: 140px;

          &:hover {
            background: #eee;
            color: #3166ae;
          }
        }
      }
    }
  }

  .lifclisBox {
    border: 1px solid #3166ae;
    border-radius: 4px;
    padding: 8px;
    height: calc(100% - 72px);
    margin-top: 10px;
    overflow: hidden;
    overflow-y: auto;
  }

  .bedSubtotal {
    // height: 42px;
    // line-height: 42px;
  }

  .tongluBox {
    text-align: center;
    margin: 40px 0;
    color: #666666;
  }

  .patientList {
    width: 216px;
    margin-right: 10px;

    .my-patient-check {
      margin-top: 10px;
    }
  }

  .tooltipCont {
    padding: 10px;
    padding-bottom: 2px;

    >div {
      margin-bottom: 8px;
    }
  }

  // .patientName {
  //   margin-bottom: 4px;
  // }
  .cont-table {
    width: calc(100% - 226px);
    height: 100%;
    overflow: hidden;

    .operation {
      margin-top: 10px;
    }

    .table {
      margin-top: 10px;
      user-select: none;
      position: relative;
      height: calc(100% - 80px);

      .thead-cell {
        padding: 2px;
        min-height: 48px;
        cursor: pointer;

        .fa-times-circle {
          color: red;
          position: absolute;
          top: 1px;
          right: 1px;
          font-size: 18px;
          cursor: pointer;
          display: none;
        }

        &:hover {
          background-color: rgba(49, 102, 174, 0.3);

          .fa-times-circle {
            display: inline-block;
          }
        }

        &.active {
          background-color: rgb(49, 102, 174, 0.5);
        }
      }

      .bedcul {
        background-color: rgba(234, 239, 247);
        border-bottom: 1px solid;
        border-color: #fff;
        min-height: 48px;
        line-height: 48px;
      }

      .plain {
        margin-bottom: 4px;
      }

      &:deep(.el-table) {

        tr:hover,
        .el-table__body tr:hover>td.el-table__cell {
          background-color: #ffffff;
          cursor: initial;
        }

        .el-table__body tr>td.el-table__cell {
          padding: 0;

          .cell {
            padding: 0;
          }
        }

        .el-table__body tr>td.el-table__cell:nth-child(1) {
          background-color: rgba(234, 239, 247);
          border-color: #fff;

          .cell {
            padding: 0 12px;
          }
        }
      }
    }
  }

  .printArea {
    border: 1px solid #000;
    /* border-right: 1px solid #000; */
    /* border-right: 0; */
    /* padding: 1px; */
    /* width: 100%; */
    /* position: absolute; */
    position: absolute;
    top: -120px;
    z-index: -1;
    left: -105px;
    transform: scale(0.8);
    /* top: 0; */
    /* z-index: 99; */
    /* left: 0; */
    /* transform: scale(0.8) translateX(-123px) translateY(-120px); */
    /* transform: scale(0.85) translateX(-160px); */

    /* z-index: -1; */
    .printTab {
      /* border: 1px solid #000; */
      /* margin-right: 1px; */
    }

    &:deep(.el-table) {
      th {
        background-color: #fff !important;
      }
    }
  }

  .el-form {
    margin-top: 0;
  }

  .border {
    height: 56px;
    background-color: #fff;
    border-radius: 8px;
    padding: 2px 10px;
    display: flex;
    justify-content: space-between;
  }

  .btnr {
    display: flex;
    align-items: center;
    height: 32px;
  }

  .bodey {
    background: #ffffff;
    border-radius: 4px;
    padding: 10px;
    height: 100%;
  }

  .bodyrodoi {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .rodoi-left,
    .rodoi-right {
      width: 32px;
      height: 32px;
      background-color: #fff;
      cursor: pointer;
      z-index: 1;
      display: flex;
      justify-items: center;
      align-items: center;

      &.default {
        cursor: not-allowed;
      }

      .fa {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.24);
        text-align: center;
        color: #3166ae;
        font-size: 24px;
      }
    }

    .dialysisType {
      flex: 1;
      overflow: hidden;

      .bodyrodoiBox {
        width: calc(100% - 64px);
        position: relative;

        .bodyrodoiBoxItem {
          transition: left 0.3s;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  .radios {
    height: 32px;
    line-height: 32px;
    background: #fff;
    border-radius: 4px;
    text-align: center;
    padding: 0 10px;
    color: #3166ae;
    margin-right: 8px;
    border: 1px solid rgba(49, 102, 174, 1);
    cursor: pointer;
    white-space: nowrap;

    &.active {
      background: #3166ae;
      color: #fff;
    }
  }

  .ruboy {
    text-align: center;
    background-color: rgba(49, 102, 174, 1);
    color: #fff;
    border-radius: 8px;
    display: flex;
    min-width: 200px;
    height: 32px;
    line-height: 32px;
    justify-content: space-around;
    font-size: 14px;
  }

  .searchass {
    font-size: 25px;
    margin: 8px;
    cursor: pointer;
    color: rgba(153, 153, 153, 1);
  }

  .textclas {
    color: rgba(49, 102, 174, 1);
    font-size: 18px;
    text-align: center;
    height: 45px;
    line-height: 45px;
    margin-left: 10px;
  }

  .lifclis {
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    padding: 0 10px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &.active {
      background: rgba(49, 102, 174, 0.3);
    }

    &:hover {
      background: rgba(49, 102, 174, 0.1);
    }
  }

  .Fclis {
    // width: 44px;
    padding: 0 8px;
    height: 24px;
    background-color: rgba(217, 236, 255, 1);
    border-radius: 6px;
    opacity: 1;
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: rgba(64, 158, 255, 1);
    line-height: 24px;
    font-size: 12px;
    text-align: center;
    margin-right: 5px;
  }

  .nacliss {
    color: rgba(49, 102, 174, 1);
    // width: 80px;
    // text-align: center;
    font-size: 14px;
  }

  .numbclss {
    width: 18px;
    height: 18px;
    line-height: 18px;
    background: rgba(255, 62, 62, 0.1);
    font-size: 12px;
    color: rgba(255, 62, 62, 1);
    border-radius: 18px;
    text-align: center;
  }

  .numbcls {
    width: 18px;
    height: 18px;
    line-height: 18px;
    background: rgba(49, 102, 174, 0.3);
    font-size: 12px;
    color: rgba(49, 102, 174, 1);
    border-radius: 18px;
    text-align: center;
  }

  .el-button--text {
    margin-right: 15px;
  }

  .el-select {
    width: 300px;
  }

  .iconcliss {
    width: 32px;
    height: 32px;
    background: #3166ae;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    color: #fff;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    font-size: 18px;
  }

  .textclss {
    font-size: 18px;
    width: 18px;
    height: 32px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #444444;
    line-height: 32px;
  }
  .sss1 .el-table__body-wrapper .el-scrollbar__bar .el-scrollbar__thumb {
    background-color: #878e95 !important;
    height: 100px !important;
    opacity: 0.7 !important;
}
</style>