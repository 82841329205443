<template>
  <div class="patentList fatherHeight contBox">
    <div id="panelParent" style="width: 210mm; height: 297mm;background-color: bisque;">
      <VueOfficeExcel :options="{'width': '1600px','height': '1400px'}" :src="excel" style="font-family: Microsoft YaHei !important;color: #000!important;"/>
    
    123  </div>
  </div>
</template>
<script setup>
  import axios from 'axios'
  import comm from '@/utils/comm'
  //引入VueOfficeExcel组件
  import VueOfficeExcel from '@vue-office/excel'
  //引入相关样式
  import '@vue-office/excel/lib/index.css'
  import { ref, onMounted, defineExpose,nextTick } from 'vue';
  const info = ref('')
  const excel = ref('')
  const initData = (item) => {
    info.value = item
    let data = {
      week: info.value
    }
    let url = '/api/export/scheduleBed'
    let port = location.protocol
    let ip = comm.cesuUrl
    let requestUrl = `${port}//${ip}${url}`
    axios({
      headers: {
        token: localStorage.getItem('ms_token'),
      },
      method: 'post',
      responseType: 'blob',
      url: requestUrl,
      data: data,
    })
      .then((res) => {
        const link = document.createElement('a')
        let blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel',
        })
        excel.value = URL.createObjectURL(blob)
        console.log(excel.value)
        // proxy.$refs.printDemoRef.initData(state.excel)
        setTimeout(() => {
          print()
        }, 3000);
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //A4的panel设置'orient': 1
  const a4Panel = { width: 210, height: 297, paperFooter: 20, paperHeader: 40, paperNumberDisabled: true, }
  import { autoConnect, disAutoConnect, hiprint, defaultElementTypeProvider } from 'vue-plugin-hiprint'
  // disAutoConnect()// 取消自动连接直接打印客户端
  hiprint.init()//初始化
  const print = (title) => {
    let hiprintTemplate = new hiprint.PrintTemplate();
    let panel = hiprintTemplate.addPrintPanel(a4Panel);
    panel.addPrintHtml({ options: { width: 210, height: 297, top: 40, left: 10, content: $('#panelParent').html() } });
    hiprintTemplate.print();
  }
  defineExpose({
    print,
    initData,
    excel
  })
</script>
<style scoped>
  .patentList {
    background-color: #fff;
  }

  #panelParent {
    height: ;
  }
</style>