<template>
    <div class="patentList fatherHeight contBox">
      <vue-office-excel :options="editorOptions" :src="excel"/>
    </div>
  </template>
  <script>
    //引入VueOfficeExcel组件
    import VueOfficeExcel from '@vue-office/excel'
    //引入相关样式
    import '@vue-office/excel/lib/index.css'
    import {
      reactive,
      toRefs,
      ref,
      nextTick,
      onMounted,
      onBeforeUnmount,
    } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import service from '@/utils/request'
    import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'
    export default {
      name: 'patentList',
      components: {
        VueOfficeExcel
      },
      props:{
        excel:{
          type:String,
          default:''
        }
      },
      setup(props1) {
        const state = reactive({
          editorOptions: {
            toolbar: true,
            width: '600px',
            height: '400px'
          },
        })
        onMounted(() => {
          console.log(222, props1.excel)
        })
        return {
          ...toRefs(state),
        }
      },
    }
  </script>
  
  <style scoped lang="scss">
    #printArea {
      /* transform: scale(0.85) translateX(-160px); */
    }
  </style>